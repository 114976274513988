@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi/Satoshi-Variable.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
}

.btn {
  text-transform: none !important;
  background-color: #0f0049 !important;
  color: #ffffff !important;
  border-radius: 2rem !important;
  padding: 0.5rem 1.25rem !important;
  font-weight: 600 !important;
}

.primary-btn {
  margin-left: 0.5rem !important;
  border-radius: 3rem !important;
  background-color: #2069f8 !important;
  color: #ffffff !important;
  font-weight: 700;
  padding: 0.5rem 1.25rem !important;
  text-transform: none !important;
  width: max-content;
}

.secondary-btn {
  text-transform: none !important;
  background-color: #ffffff !important;
  color: #cfcfcf !important;
  border: 1px solid #cfcfcf !important;
  border-radius: 2rem !important;
  padding: 0.4rem 1.5rem !important;
  font-weight: 600 !important;
}

.secondary-btn:hover {
  background-color: #1565c0;
}

.secondary-btn1 {
  text-transform: none !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
  border-radius: 2rem !important;
  padding: 0.4rem 1.5rem !important;
  font-weight: 600 !important;
}

.primary-btn:hover {
  background-color: #1565c0;
}
